<template>
    <zw-sidebar @shown="shown" :title="$t('article.title.room')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-card :header="$t('article.group.basic')">
                    <b-card-text>
                        <b-row>
                            <b-col cols="3">
                                <zw-input-group v-model="form.name"
                                                name="name"
                                                :label-prefix="labelPrefix"
                                                validate="required"
                                ></zw-input-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-input-group v-model="form.sku"
                                                name="sku"
                                                :label-prefix="labelPrefix"
                                                validate="required"
                                ></zw-input-group>
                            </b-col>

                            <b-col cols="3">
                                <b-form-group :label="$t(labelPrefix+'categories')">
                                    <multiselect
                                        v-model="form.category_ids"
                                        :options="Object.keys(getCategoryOptions()).map(cat=>parseInt(cat))"
                                        :multiple="true"
                                        @input="updateName"
                                        :custom-label="opt => getCategoryOptions()[opt]"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-select-group v-model="form.type"
                                                 :options="getTypes() | optionsVV"
                                                 name="type"
                                                 :label-prefix="labelPrefix"
                                                 validate="required"
                                ></zw-select-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['star_type']"
                                              :field="form.customFields['star_type']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <zw-select-group v-model="form.customValues['operator']"
                                                 :name="'custom.'+form.customFields['operator'].model+'.'+form.customFields['operator'].name"
                                                 :label="form.customFields['operator'].options.label"
                                                 :options="getProfilesList()"
                                                 valueField="id"
                                                 textField="name"
                                ></zw-select-group>
                            </b-col>

                            <b-col cols="3">
                                <b-form-group>
                                    <template v-slot:label>
                                        {{ form.customFields['employees'].options.label }}
                                    </template>
                                    <multiselect v-model="form.customValues['employees']"
                                                 :options="getUsersList()"
                                                 :searchable="false"
                                                 :multiple="true"
                                                 label="fullname"
                                                 track-by="id"
                                                 :show-labels="false"
                                                 placeholder=""
                                                 select-label=""
                                    ></multiselect>
                                </b-form-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-select-group v-model="form.customValues['anlagen']"
                                                 :options="anlagens"
                                                 name="anlagen"
                                                 label="Anlagen"
                                                 :label-prefix="labelPrefix"
                                                 text-field="name"
                                                 value-field="id"
                                ></zw-select-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <zw-ckeditor v-model="form.description"></zw-ckeditor>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-card :header="$t('article.group.address')">
                    <b-card-text>
                        <b-row>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['address_street']"
                                              :field="form.customFields['address_street']"
                                              @input="updateName"
                                ></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['address_nr']"
                                              :field="form.customFields['address_nr']"
                                              @input="updateName"
                                ></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['address_zip']"
                                              :field="form.customFields['address_zip']"
                                              @input="updateName"
                                ></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['address_city']"
                                              :field="form.customFields['address_city']"
                                              @input="updateName"
                                ></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['address_location']"
                                              :field="form.customFields['address_location']"
                                              @input="updateName"
                                ></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['address_owner']"
                                              :field="form.customFields['address_owner']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['beds']"
                                              :field="form.customFields['beds']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['reserve_bed']"
                                              :field="form.customFields['reserve_bed']"></custom-field>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-card :header="$t('article.group.rental')">
                    <b-card-text>
                        <b-row>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['rent_without_vat']"
                                              :field="form.customFields['rent_without_vat']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['rent_with_vat']"
                                              :field="form.customFields['rent_with_vat']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <zw-input-group label="Gesamtkosten / Überschus"
                                                v-model="form.customValues['rent_total']"
                                                name="rent_total"
                                                readonly
                                ></zw-input-group>

                                <!--                                <custom-field v-model="form.customValues['rent_total']"-->
                                <!--                                              :field="form.customFields['rent_total']"></custom-field>-->
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['calculated_earnings']"
                                              :field="form.customFields['calculated_earnings']"
                                              readonly
                                ></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <zw-input-group v-model="form.sales_price"
                                                name="selling_price_per_night"
                                                :label-prefix="labelPrefix"
                                                validate="required"
                                                @input="form.customValues['calculated_earnings'] = parseFloat(form.sales_price) * 30"
                                ></zw-input-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-input-group v-model="form.quantity"
                                                name="quantity"
                                                :label-prefix="labelPrefix"
                                                type="number"
                                ></zw-input-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-select-group v-model="form.unit"
                                                 :options="getUnits() | optionsVL"
                                                 name="unit"
                                                 :label-prefix="labelPrefix"
                                                 validate="required"
                                ></zw-select-group>
                            </b-col>

                            <b-col cols="3">
                                <zw-select-group v-model="form.vat"
                                                 :options="getVats() | optionsVL"
                                                 name="vat"
                                                 :label-prefix="labelPrefix"
                                                 validate="required"
                                ></zw-select-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-card :header="$t('article.group.costs')">
                    <b-card-text>
                        <b-row>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['electricity_supplier']"
                                              :field="form.customFields['electricity_supplier']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['electricity_meter']"
                                              :field="form.customFields['electricity_meter']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['electricity_customer_number']"
                                              :field="form.customFields['electricity_customer_number']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['electricity_monthly_fee']"
                                              :field="form.customFields['electricity_monthly_fee']"
                                              @input="updateTotal"
                                ></custom-field>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['gas_supplier']"
                                              :field="form.customFields['gas_supplier']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['gas_meter']"
                                              :field="form.customFields['gas_meter']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['gas_customer_number']"
                                              :field="form.customFields['gas_customer_number']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['gas_monthly_fee']"
                                              :field="form.customFields['gas_monthly_fee']"
                                              @input="updateTotal"
                                ></custom-field>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['internet_provider']"
                                              :field="form.customFields['internet_provider']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['internet_contract_number']"
                                              :field="form.customFields['internet_contract_number']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['internet_password']"
                                              :field="form.customFields['internet_password']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['internet_monthly_fee']"
                                              :field="form.customFields['internet_monthly_fee']"
                                              @input="updateTotal"
                                ></custom-field>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['garbage']"
                                              :field="form.customFields['garbage']"
                                              @input="updateTotal"
                                ></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['other1']"
                                              :field="form.customFields['other1']"
                                              @input="updateTotal"
                                ></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['other2']"
                                              :field="form.customFields['other2']"
                                              @input="updateTotal"
                                ></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['total_costs']"
                                              :field="form.customFields['total_costs']"
                                              :readonly="true"
                                ></custom-field>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['commission_amount']"
                                              :field="form.customFields['commission_amount']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['commission_recipient']"
                                              :field="form.customFields['commission_recipient']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['hot_water_supply']"
                                              :field="form.customFields['hot_water_supply']"></custom-field>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['heater']"
                                              :field="form.customFields['heater']"></custom-field>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>


                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-row align-h="end">
                            <b-col sm="3" class="text-sm-right">
                                <b-button block @click="onSubmit()" variant="primary">
                                    {{ $t('common.button.save') }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                quantity: 1,
            },
            form: {},
            labelPrefix: 'article.label.',
            anlagens: [],
        }
    },
    methods: {
        ...mapGetters('Articles', ['getArticle', 'getTypes']),
        ...mapGetters('Users', ['getUsersList']),
        ...mapGetters('Profiles', ['getProfilesList']),
        ...mapGetters('CommonData', ['getSettings', 'getUnits', 'getVats']),
        ...mapGetters('Category', ['getCategories', 'getCategoryOptions']),
        shown() {
            const usersList = this.$store.dispatch('Users/fetchUsersList')
            const profilesList = this.$store.dispatch('Profiles/fetchProfilesList')
            const articles = this.$store.dispatch('Articles/fetchArticle', this.payload.id)
            const categories = this.$store.dispatch('Category/fetchOptions')
            const articleTypes = this.$store.dispatch('Articles/fetchTypes')

            this.$store.dispatch('Articles/fetchCategoryArticles', this.getSettings()['room_parent_category']).then((response) => {
                this.anlagens = response.data.articles || []
            })

            Promise.all([usersList, profilesList, articles, categories, articleTypes])
                .then((values) => {
                    this.form = {
                        ...this.defaultForm,
                        ...{category_ids: [this.getSettings()['booking_room_category']]},
                        ...JSON.parse(JSON.stringify(this.getArticle()))
                    }
                    if(!this.form.category_ids.length) {
                        this.form.category_ids = [this.getSettings()['booking_room_category']]
                    }
                    this.form.customValues['employees'] = JSON.parse(this.form.customValues['employees'])
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.form.customValues['employees'] = JSON.stringify(this.form.customValues['employees'])
                    this.$store.dispatch('Articles/saveArticle', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        updateName() {
            let values = []

            if (this.form.category_ids) {
                this.form.category_ids.forEach((id) => {
                    values.push(this.getCategoryOptions()[id].replace(/^(-)+/, ""))
                })

            }
            if (this.form.customValues['address_street']) {
                values.push(this.form.customValues['address_street'])
            }
            if (this.form.customValues['address_nr']) {
                values.push(this.form.customValues['address_nr'])
            }
            if (this.form.customValues['address_zip']) {
                values.push(this.form.customValues['address_zip'])
            }
            if (this.form.customValues['address_city']) {
                values.push(this.form.customValues['address_city'])
            }
            if (this.form.customValues['address_location']) {
                values.push(this.form.customValues['address_location'])
            }

            if (values.length) {
                this.form.name = values.join(' ')
            }
        },
        updateTotal() {
            let total = 0
            let fields = ['electricity_monthly_fee', 'gas_monthly_fee', 'internet_monthly_fee', 'garbage', 'other1', 'other2']
            fields.forEach(key => {
                if (this.form.customValues[key]) {
                    total += parseFloat(this.form.customValues[key])
                }
            })
            this.form.customValues['total_costs'] = total
        }
    },
    watch: {
        'form.customValues': {
            handler: function () {
                let first = parseFloat(this.form.customValues['rent_without_vat'] || 0) + parseFloat(this.form.customValues['total_costs'] || 0)
                let second = parseFloat(this.form.customValues['calculated_earnings'] || 0) - first

                this.form.customValues['rent_total'] = first + '€ / ' + second + '€'
            },
            deep: true
        }
    },
}
</script>