import { render, staticRenderFns } from "./RoomArticleModal.vue?vue&type=template&id=2c9f91a0&"
import script from "./RoomArticleModal.vue?vue&type=script&lang=js&"
export * from "./RoomArticleModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports